<template>
  <main>
    <form autocomplete="off" spellcheck="false" @submit.prevent="shortenUrl()">
      <div v-if="error" class="error">
        {{ error }}
      </div>
      <div v-if="noApiKeyError" class="apikey--error">
        <p>
          {{ $t('header.apikeyerror.1') }}
           <nuxt-link to="/api-change">{{ $t('header.apikeyerror.2') }}</nuxt-link> {{ $t('header.apikeyerror.3') }}.
        </p>
      </div>
      <section>
        <div v-if="!$v.url.required && $v.url.$error" class="error--message">
          {{ $t('shorten.no-url') }}
        </div>
        <div class="url">
          <div v-if="!$v.url.url && $v.url.$error" class="error--message">
            {{ $t('shorten.invalid-url') }}
          </div>
          <label for="url" class="sr-only">URL</label>
          <input
            id="url"
            v-model.lazy="$v.url.$model"
            type="text"
            :placeholder="$t('shorten.placeholder.url')"
            class="input"
            :class="{ 'error--input': $v.url.$error }"
            :disabled="isSuccess"
          />
        </div>
      </section>
      <section>
        <div v-if="!$v.url.alphaNum && $v.alias.$error" class="error--message">
          {{ $t('shorten.alpha-only') }}
        </div>
        <div v-if="aliasUsedError" class="error--message">
          {{ $t('shorten.alias-used') }}
        </div>
        <div class="alias">
          <label for="alias">chl.li/</label>
          <input
            id="alias"
            v-model.lazy="$v.alias.$model"
            type="text"
            :placeholder="$t('shorten.placeholder.alias')"
            class="input"
            :class="{ 'error--input': $v.alias.$error || aliasUsedError }"
            :disabled="isSuccess"
          />
        </div>
      </section>
      <section>
        <div class="expire" @keydown.enter.prevent.self>
          <label for="expireDate">{{ $t('shorten.expiry') }}</label>
          <v-select
            v-model="expires"
            input-id="expireDate"
            :options="options"
            :disabled="isSuccess"
          />
        </div>
      </section>
      <section>
        <div class="api-key">
          <label for="api-key">API key <sup><router-link to="api-change">{{ $t('shorten.apikey-what') }}</router-link></sup></label>
          <div class="hidden-api-key" v-if="apiKeySet">
            <p v-text="apiKeyHidden"></p>
            <button @click="apiKeySet = false">{{ $t('shorten.apikey-edit') }}</button>
          </div>
           <input
            v-else
            id="api-key"
            v-model="apiKey"
            type="text"
            :placeholder="$t('shorten.placeholder.apikey')"
            class="input"
            :disabled="isSuccess"
          />
          <p>{{ $t('shorten.apikey-save') }}</p>
        </div>
      </section>
      <button
        type="submit"
        class="btn-submit"
        :class="shortenClass"
        :disabled="isSuccess"
      >
        <span>{{ shortenText }}</span>
      </button>
      <button v-if="isSuccess" class="btn-submit" @click="resetForm">
        {{ $t('shorten.new-link') }} →
      </button>
    </form>
    <div v-if="isSuccess" class="result">
      <div class="result--box">
        <h2>
          <a :href="'/' + alias" target="_blank">https://chl.li/{{ alias }}</a>
        </h2>
        <button
          v-clipboard="`https://chl.li/${alias}`"
          v-clipboard:success="copySuccess"
          :class="copyClass"
          type="button"
          class="copy"
        >
          {{ copy }}
        </button>
        <button
          type="button"
          class="qrcode"
          :class="{ 'qrcode-clicked': showQrCode }"
          @click="revealQrCode"
        >
          <span class="sr-only">{{ qrcode }}</span>
          <img src="/icons/qr-code.svg" class="qrcode-button" />
        </button>
      </div>
      <fullscreen
        v-if="showQrCode"
        ref="fullscreen"
        class="fullscreen-wrapper"
        background="#fff"
        @change="fullscreenChange"
      >
        <div class="fullscreen-content">
          <qrcode
            :value="`https://chl.li/${alias}`"
            class="qrcode-img"
            render-as="svg"
            :size="qrCodeWidth"
            :margin="qrCodeMargin"
          ></qrcode>
          <p v-if="fullscreen" class="fullscreen-text">
            <a
              :href="'/' + alias"
              target="_blank"
              :style="`font-size: ${alias.length > 6 ? 8 : 12}vmin`"
              >https://chl.li/{{ alias }}</a
            >
          </p>
        </div>
        <button
          v-if="this.$fullscreen.support"
          type="button"
          class="fullscreen-button tooltip-light"
          :aria-label="[fullscreen ? 'Exit Fullscreen' : 'View Fullscreen']"
          :data-balloon-pos="[fullscreen ? 'left' : 'up-left']"
          @click="toggleFullscreen"
        >
          <span class="sr-only">Toggle fullscreen</span>
          <img
            :src="
              fullscreen
                ? '/icons/fullscreen-exit.svg'
                : '/icons/fullscreen.svg'
            "
            class="fullscreen-button-button"
          />
        </button>
      </fullscreen>
      <div class="support">
        Enjoy using chl.li? Consider supporting the project with a donation on
        <a href="https://ko-fi.com/vincentdoerig">Ko-fi</a>. Thank you!
      </div>
    </div>
  </main>
</template>

<script>
import { required, url, alphaNum } from 'vuelidate/lib/validators'
import DateOptions from '@/middleware/dateOptions'
import UrlService from '@/middleware/urlService'
import '@/assets/styles/balloon.min.css'
import 'vue-select/dist/vue-select.css'

export default {
  data() {
    return {
      locale: this.$i18n.locale,
      options: DateOptions[this.$i18n.locale],
      isSuccess: false,
      error: '',
      aliasUsedError: false,
      noApiKeyError: false,
      alias: '',
      url: '',
      expires: {
        label: this.$t('shorten.never'),
        value: 0,
      },
      copy: this.$t('shorten.copy'),
      copyClass: '',
      shortenText: this.$t('shorten.shorten-text'),
      shortenClass: '',
      showQrCode: false,
      qrcode: this.$t('shorten.qrcode'),
      qrCodeWidth: 450,
      qrCodeMargin: 2,
      fullscreen: false,
      apiKey: '',
      apiKeySet: false,
    }
  },
  validations: {
    url: {
      required,
      url,
    },
    alias: {
      alphaNum,
    },
  },
  mounted () {
    const hasAPIKey = window.localStorage.getItem('api_key')
    if (hasAPIKey) {
      this.apiKey = hasAPIKey
      this.apiKeySet = true
      this.noApiKeyError = false
    }
  },
  methods: {
    async shortenUrl() {
      this.url = encodeURI(this.url)
      this.$v.$touch()
      if (
        !this.url.startsWith('http://') &&
        !this.url.startsWith('https://') &&
        this.url !== ''
      ) {
        this.url = 'http://' + this.url
      }
      if (this.$v.$invalid) {
        this.isSuccess = false
        this.shortenText = this.$t('shorten.shorten-text')
        this.shortenClass = ''
      } else {
        this.shortenText = this.$t('shorten.shortening')
        this.shortenClass = 'loading'
        this.error = ''
        this.aliasUsedError = false
        const body = {
          alias: this.alias,
          url: this.url,
          expires: Number(this.expires.value),
        }
        try {
          const response = await UrlService.shortenUrl(body, this.apiKey)
          this.shortenText = this.$t('shorten.shortened')
          this.shortenClass = 'success'
          this.alias = response.data.alias
          this.isSuccess = true
          if (this.apiKey) {
            this.apiKeySet = true
            window.localStorage.setItem('api_key', this.apiKey)
          }
        } catch (error) {
          // reset button text and class
          this.shortenText = this.$t('shorten.shorten-text')
          this.shortenClass = ''
          if (error.response.data[0].message.startsWith('API key required.')) {
            this.noApiKeyError = true
            return
          }
          if (!error.response || !Array.isArray(error.response.data)) {
            // return axios error message if no error message has been received from the server
            this.error = `${error.response.statusText}. ${error.message}.`
            return
          }
          // server-side error handling
          const errors = error.response.data

          if (errors[0].message === 'Ending is already in use.') {
            this.aliasUsedError = true
            return
          }
          this.error = errors.map(errors => errors.message).join(' ')
        }
      }
    },
    copySuccess() {
      this.copyClass = 'success'
      setTimeout(() => (this.copy = this.$t('shorten.copied')), 150)
      setTimeout(() => {
        this.copy = this.$t('shorten.copy')
        this.copyClass = ''
      }, 2500)
    },
    copyError() {
      this.copyClass = 'error'
      this.copy = this.$t('shorten.copy-failure')
    },
    revealQrCode() {
      this.showQrCode = !this.showQrCode
      this.qrcode = this.showQrCode
        ? this.$t('shorten.qrcode-hide')
        : this.$t('shorten.qrcode')
    },
    toggleFullscreen() {
      // eslint-disable-next-line dot-notation
      this.$refs['fullscreen'].toggle()
    },
    fullscreenChange(fullscreen) {
      this.fullscreen = fullscreen
    },
    resetForm() {
      // reset all values
      this.isSuccess = false
      this.error = ''
      this.aliasUsedError = false
      this.noApiKeyError = false
      this.alias = ''
      this.url = ''
      this.expires = {
        label: this.$t('shorten.never'),
        value: 0,
      }
      this.shortenText = this.$t('shorten.shorten-text')
      this.shortenClass = ''
      this.showQrCode = false

      // reset validation errors
      this.$v.$reset()
    },
  },
  computed: {
    apiKeyHidden () {
      const key = this.apiKey
      const length = key.length
      const known = key.substring(0, 8)
      return `${known}${'X'.repeat(20)}`
    }
  }
}
</script>
