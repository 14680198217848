<template>
  <header>
    <api-change-banner />
    <h1 class="title">
      <span class="title--link">chl.li</span>
    </h1>
    <p class="info">
      CHL(INE) LI(NK) — ‘chline link’ [ˈxlinə link] ({{ $t('header.info') }})
    </p>
    <p class="lead">{{ $t('header.lead') }}</p>
  </header>
</template>

<script>
import ApiChangeBanner from './ApiChangeBanner.vue'

export default {
  components: { ApiChangeBanner },}
</script>
