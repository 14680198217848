<template>
  <div class="app">
    <Header />
    <UrlShortener />
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import UrlShortener from '@/components/UrlShortener'
import Footer from '@/components/Footer'

export default {
  components: {
    Header,
    UrlShortener,
    Footer,
  },
  head() {
    return { title: this.$t('meta.index') }
  },
}
</script>

<style lang="scss" scoped>
footer {
  margin-bottom: -6px;
}
</style>
