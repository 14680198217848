<template>
  <!--  As of August 1, 2021, new chl.li links will have to be created using an API key. Existing links will continue to work as normal. -->
  <div class="banner">
    <div class="banner--content">
      <div class="pr-16 sm:text-center sm:px-16">
        <p class="font-medium text-white">
          {{ $t('header.apinotice') }}
          <span>
            <router-link to="api-change"> {{ $t('header.apinoticemore') }} <span aria-hidden="true">&rarr;</span></router-link>
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>
